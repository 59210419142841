import { Injectable } from '@angular/core';

/**
 * A simple service with methods related to urls
 */
@Injectable({
  providedIn: 'root'
})
export class UrlService {

  openUrlInNewTab(url: string) {
    window.open(url, '_blank');
  }

  setLocationHref(url: string) {
    window.location.href = url;
  }
}
