import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'environments/environment';
import { first } from 'rxjs';
import authDevSettings from '../local-settings/auth.local.settings.json';
import devSettings from '../local-settings/local.settings.json';
import { AuthService } from './auth/auth.service';
import { FeatureConfigurationService } from './core/services/featureconfiguration.service';
import { ApplicationSettings } from './shared/models/ApplicationSettings.model';
import {
  BlockPrivacySettingsFeature,
  InitialOrderRedirectFeature,
  Installment2MagentaFeature,
  LateDeliveryNotificationFeature,
  SapMigrationFeature,
  VeiligOnlineProductsFeature
} from './shared/models/features';
import { CeaseFlowFeature } from './shared/models/features/cease-flow.feature';
import { CmsFeature } from './shared/models/features/cms.feature';
import { Invoices2MagentaFeature } from './shared/models/features/invoices-magenta.feature';
import { Settings2MagentaFeature } from './shared/models/features/settings-magenta.feature';

@Injectable()
export class StartupService {
  constructor(
    private readonly authService: AuthService,
    private readonly featureConfigurationService: FeatureConfigurationService
  ) {}

  load(
    blockPrivacySettingsFeature: BlockPrivacySettingsFeature,
    lateDeliveryNotificationFeature: LateDeliveryNotificationFeature,
    sapMigrationFeature: SapMigrationFeature,
    veiligOnlineProductsFeature: VeiligOnlineProductsFeature,
    settings2MagentaFeature: Settings2MagentaFeature,
    invoices2MagentaFeature: Invoices2MagentaFeature,
    cmsFeature: CmsFeature,
    installment2MagentaFeature: Installment2MagentaFeature,
    initialOrderRedirectFeature: InitialOrderRedirectFeature,
    ceaseFlowFeature: CeaseFlowFeature
  ): Promise<any> {
    // We trigger it at least once, to make sure the feature configuration is loaded before any component is initialized
    const request = this.featureConfigurationService.featureConfiguration
      .pipe(first())
      .toPromise()
      .then((config) => {
        blockPrivacySettingsFeature.isEnabled = config?.blockPrivacySettingsFeatureIsEnabled === true;
        lateDeliveryNotificationFeature.isEnabled = config?.lateDeliveryNotificationFeatureIsEnabled === true;
        sapMigrationFeature.isEnabled = config?.sapMigrationFeatureIsEnabled === true;
        veiligOnlineProductsFeature.isEnabled = config?.veiligOnlineProductsFeatureIsEnabled === true;
        settings2MagentaFeature.isEnabled = config?.settings2MagentaFeatureIsEnabled === true;
        invoices2MagentaFeature.isEnabled = config?.invoices2MagentaFeatureIsEnabled === true;
        installment2MagentaFeature.isEnabled = config?.installment2MagentaFeatureIsEnabled === true;
        cmsFeature.isEnabled = config?.cmsFeatureIsEnabled === true;
        initialOrderRedirectFeature.isEnabled = config?.initialOrderRedirectFeatureIsEnabled === true;
        ceaseFlowFeature.isEnabled = config?.ceaseFlowFeatureIsEnabled === true;

        (window as any).settings2MagentaFeature = config?.settings2MagentaFeatureIsEnabled === true;
        (window as any).invoices2MagentaFeature = config?.invoices2MagentaFeatureIsEnabled === true;
      })
      .catch((rejected) => {
        if (!(rejected instanceof HttpErrorResponse && rejected.status === 503)) {
          setTimeout(() => {
            throw rejected;
          });
        }
      });

    return Promise.all([request, this.initializeAuthorization()] as [Promise<void>, Promise<void>]);
  }

  private async initializeAuthorization(): Promise<void> {
    let settings;

    if (environment.production) {
      settings = (window as any).authSettings as ApplicationSettings;
    } else {
      settings = authDevSettings as ApplicationSettings;

      (window as any).magentaBaseUrl = devSettings.magentaBaseUrl;
      (window as any).magentaAccountUrl = devSettings.magentaAccountUrl;
      (window as any).magentaSubscriptionUrl = devSettings.magentaSubscriptionUrl;
      (window as any).espnPriceChangeCalloutFromDate = devSettings.espnPriceChangeCalloutFromDate;
      (window as any).espnPriceChangeCalloutToDate = devSettings.espnPriceChangeCalloutToDate;
    }

    const rootUrl = window.location.origin;

    if (settings) {
      const authConfig = {
        issuer: settings.identityServerUrl,
        clientId: 'selfcare.client',
        responseType: 'code',
        scope: 'openid selfcare.api customer',
        useSilentRefresh: true,

        redirectUri: rootUrl + settings.redirectUri,
        postLogoutRedirectUri: settings.postLogoutRedirectUri || rootUrl + '/',
        silentRefreshRedirectUri: rootUrl + settings.silentRedirectUri
      } as AuthConfig;

      await this.authService.initialize(authConfig, settings);
    }
  }
}
