<section class="section section-square-pattern">
  <div class="container maintenanceMessage">
    <h2>
      <b> Odido tijdelijk niet beschikbaar </b>
    </h2>
    <p>
      Om je altijd de beste ervaring te geven op onze website zijn we druk bezig met onderhoud. Hierdoor kun je even geen gebruik
      maken van Odido. We zijn zo snel mogelijk weer beschikbaar.
    </p>
    <p>
      Heb je vragen? We hebben ook veel antwoorden al voor je online gezet op
      <a href="https://www.odido.nl/service" class="text-nowrap">Odido Service.</a>
    </p>
  </div>
</section>
