export class Endpoints {
  // Account
  static readonly account = '/api/account';
  static readonly accountBasic = '/api/account/basic';
  static readonly accountChangePassword = '/api/account/changePassword';
  static readonly accountContractInfo = '/api/account/contractinfo';
  static readonly accountContractPdf = '/api/account/contractpdf';
  static readonly accountCustomerInfo = '/api/account/customerinfo';
  static readonly accountPaymentInformation = '/api/account/paymentinformation';
  static readonly accountPrimaryEmail = '/api/account/setPrimaryEmailAdress';
  static readonly accountPrivacy = '/api/account/privacysettings';
  static readonly accountSetPhoneNumbers = '/api/account/setPhoneNumbers';

  // Address Lookup
  static readonly addressLookup = '/api/AddressLookup/{0}/{1}';

  static readonly ceaseFrom = '/api/cease';

  // change offers
  static readonly changeOfferNetworkMigration = '/api/changeoffer/networkmigration?selectedInternetProduct={0}&wishDate={1}';

  // Converged
  static readonly converged = '/api/converged';

  // Credits
  static readonly credits = '/api/credits';

  // entertainment
  static readonly entertainmentViaplay = '/api/entertainment/viaplay';

  // fsecure
  static readonly fSecureCustomer = '/api/fsecure/customer';

  // features
  static readonly features = '/api/features';

  // feedback
  static readonly feedback = '/api/feedback';
  static readonly feedbackVerify = '/api/feedback/{0}';

  // gdpr
  static readonly gdprAvailable = '/api/gdpr';
  static readonly gdprData = '/api/gdpr/data';

  // household
  static readonly householdMayJoin = '/api/household/mayjoin';
  static readonly householdSubscribe = '/api/allesonder1dak/Subscribe';
  static readonly householdSubscribeAddress = '/api/allesonder1dak/GetSubscribeAddress';
  static readonly householdUnsubscribe = '/api/allesonder1dak/Unsubscribe';
  static readonly householdUri = '/api/allesonder1dak';

  // installmentplan
  static readonly installmentplan = '/api/installmentplan';
  static readonly installmentplanEligibility = '/api/installmentplan/eligibility';

  // invoices
  static readonly invoices = '/api/invoices';
  static readonly invoicesDunningStatus = '/api/invoices/dunningstatus';
  static readonly invoicesOpenBalance = '/api/invoices/openbalance';
  static readonly invoicesPdf = '/api/invoices/pdf';
  static readonly invoicesSpecificPdf = '/api/invoices/pdf/{0}';

  // Logging
  static readonly log = '/api/log';
  static readonly logExternal = '/api/log/external';

  // move
  static readonly move = '/api/move';
  static readonly moveOffer = '/api/move/offer';
  static readonly moveToAddress = '/api/move?address.postalCode={0}&address.houseNumber={1}&address.houseNumberAddition={2}';

  // optout
  static readonly optOutPostV2 = '/api/optout/v2';
  static readonly optOutV2 = '/api/optout/v2/{0}';

  // Orders
  static readonly ordersRouteAnyOpen = '/api/orders/open/any';
  static readonly ordersRouteCompleted = '/api/orders/completed/{0}/{1}';
  static readonly ordersRouteDetails = '/api/orders/{0}';
  static readonly ordersRouteInitial = '/api/orders/initial';
  static readonly ordersRouteOpenAll = '/api/orders/open';
  static readonly ordersRouteProductOrderHistory = '/api/orders/productOrderHistory';
  static readonly ordersRouteShippingAddress = '/api/orders/shippingaddress';
  static readonly ordersShippingLabel = '/api/orders/shippinglabel/{0}/{1}';
  static readonly ordersShippingLabelCarrier = '/api/orders/shippinglabelCarrier/{0}';

  // Outage
  static readonly outageIncidents = '/api/outage/incidents';

  // postponepayment
  static readonly postponepayment = '/api/postponepayment';

  // proactive swap
  static readonly proactiveSwapGetProducts = '/api/proactiveswap/products';
  static readonly proactiveSwapPlaceOrder = '/api/proactiveswap/placeorder';

  // products
  static readonly productInternet = '/api/product/internet';
  static readonly productNetworkMigration = '/api/product/place-network-migration-order';
  static readonly productNetworkMigrationPreOrder = '/api/product/place-network-migration-pre-order';
  static readonly productRecording = '/api/product/recording';

  static readonly productsAdd = '/api/products/add';
  static readonly productsAvailable = '/api/products/available';
  static readonly productsCease = '/api/products/cease';
  static readonly productsModify = '/api/products/modify';
  static readonly productsTv = '/api/products/tv';
  static readonly productsTvByAddress = '/api/products/tv?address.postalCode={0}&address.houseNumber={1}&address.houseNumberAddition={2}';
  static readonly productsVoip = '/api/products/voip';
  static readonly productsWifiExtender = '/api/products/wifiextender';

  // Remote assets
  static readonly remoteAssets = '/api/remote-assets/{0}';

  // remote mechanic
  static readonly remoteMechanic = '/api/remotemechanic';
  static readonly remoteMechanicOpenSupportRequests = '/api/remotemechanic/opensupportrequests';

  // Settopboxes
  static readonly setTopBoxesInfo = '/api/settopboxes/info';

  // subscription
  static readonly subscription = '/api/subscription';
  static readonly subscriptionStatus = '/api/subscription/status';

  // transfer
  static readonly transfer = '/api/transfer';
  static readonly transferByReference = '/api/transfer/{0}';
  static readonly transferUpdate = '/api/transfer/update';
  static readonly transferUpdateByReference = '/api/transfer/update-by-reference';

  // tv settings
  static readonly tvSettings = '/api/tvsettings';

  // Usage
  static readonly usageVod = '/api/usage/vod';
  static readonly usageVoip = '/api/usage/voip';

  // UserData
  static readonly userData = '/api/userdata/user';

  // voip settings
  static readonly voipCheckNetCode = '/api/voipsettings/checknetcode?phoneNumber={0}&postalCode={1}';
  static readonly voipSettings = '/api/voipsettings';
  static readonly voipSettingsNumberBlocking = '/api/voipsettings/numberblocking';
  static readonly voipSettingsNumberPorting = '/api/voipsettings/numberporting';
  static readonly voipSettingsSipPassword = '/api/voipsettings/sipPassword';
  static readonly voipSettingsVoicemail = '/api/voipsettings/voicemailAndForwarding';

  // vouchers
  static readonly voucherApply = '/api/voucher/applycontent';
  static readonly voucherUnApply = '/api/voucher/unapplycontent';
}
